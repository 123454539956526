import { Route } from '@angular/compiler/src/core';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { PasswordConfirmationValidatorService } from 'src/core/services/password-confirmation-validators.service';
import { RegisterService } from 'src/core/services/register.service';
import { ChangePasswordModel } from 'src/shared/models/account/change-password.model';

@Component({
  selector: 'app-change-password-dailog',
  templateUrl: './change-password-dailog.component.html',
  styleUrls: ['./change-password-dailog.component.css']
})
export class ChangePasswordDailogComponent implements OnInit {

  changePasswordForm: FormGroup;
  changePasswordModel: ChangePasswordModel;
  submitted = false;
  hide = true;
  confirmhide = true;

  constructor(
    private registerService: RegisterService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordDailogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authenticationService: AuthenticationService,
    private passConfValidator: PasswordConfirmationValidatorService
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })

    this.changePasswordForm.get('confirmPassword').setValidators([Validators.required,
    this.passConfValidator.validateConfirmPassword(this.changePasswordForm.get('newPassword'))]);
  }


  validationMessages = {
    currentPassword: [
      { type: 'required', message: 'Current Password is required' },
      { type: 'minlength', message: 'Current Password must be at least 8 characters long' },
    ],
    newPassword: [
      { type: 'required', message: 'New Password is required' },
      { type: 'minlength', message: 'New Password must be at least 8 characters long' },
    ],
    confirmPassword: [
      { type: 'compare', message: 'Password and Confirm Password is not match' },
      { type: 'required', message: 'Confirm Password is required' },
      { type: 'minlength', message: 'Confirm Password must be at least 8 characters long' }
    ]

  };

  get formControls(): any { return this.changePasswordForm.controls; }

  onFormSubmit(): void {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.changePasswordModel = new ChangePasswordModel();
    this.changePasswordModel.currentPassword = this.changePasswordForm.get('currentPassword').value;
    this.changePasswordModel.newPassword = this.changePasswordForm.get('newPassword').value;


    this.registerService.changePassword(this.changePasswordModel)
      .subscribe(
        result => {
          this.snackBar.open("Password Changed Successfully.", null, {
            duration: 5 * 1000,
            horizontalPosition: 'right',
            panelClass: 'success-snackbar'
          });
          this.dialogRef.close();
        },
        err => {
          this.snackBar.open(err.error.error.message, null, {
            duration: 5 * 1000,
            horizontalPosition: 'right',
            panelClass: 'error-snackbar'
          });
        });
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
}
