import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { PagedResultDto } from '../../core/models/PagedResultDto';
import { AppConsts } from '../../core/AppConsts';
import { ResponseModel } from 'src/core/models/response/response.model';
import { UserProfileModel } from '../models/userProfile/user-profile.model';
import { GetUserProfileModel } from '../models/userProfile/get-user-profile.model';
import { MasterListDto } from 'src/core/models/master/MasterCollection';
import { MasterDataKeywordFilter } from '../../core/models/master/MasterDataKeywordFilter';
import { UserInfoModel } from '../models/userProfile/user-info.model';
import { MasterLocationKeywordFilter } from 'src/core/models/master/masterLocationKeywordFilter';
import { UserStatus } from '../models/account/user-status.enum';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    constructor(
        private apiService: ApiService
    ) {

        this.wso2AmEndpoint = AppConsts.userServiceBaseUrl;
    }

    private wso2AmEndpoint: string;

    createProfile(createProfile: UserProfileModel = new UserProfileModel()): Observable<ResponseModel> {
        const formData = new FormData();
        for (const key of Object.keys(createProfile)) {
            if (typeof (createProfile[key]) === 'object') {
                if (key === 'workCategories') {
                    for (let i = 0; i < createProfile[key].length; i++) {
                        formData.append('workCategories[' + i + '].id', createProfile[key][i].id);
                        formData.append('workCategories[' + i + '].name', createProfile[key][i].name);
                    }
                } else {
                    formData.append(key, createProfile[key] == null ? "" : createProfile[key]);
                }
            }
            else {
                formData.append(key, createProfile[key] == null ? "" : createProfile[key]);
            }
        }
        return this.apiService.post(`${this.wso2AmEndpoint}/user-profile`, formData);
    }

    get(): Observable<GetUserProfileModel> {
        return this.apiService.get(`${this.wso2AmEndpoint}/user-profile`);
    }

    getUserInfo(): Observable<UserInfoModel> {
        return this.apiService.get(`${this.wso2AmEndpoint}/user-profile/getUserInfo`);
    }

    getMasterDatas(filters: MasterDataKeywordFilter): Observable<MasterListDto[]> {
        return this.apiService.post(`${this.wso2AmEndpoint}/app/master/get-masters-datas`, filters);
    }

    getMasterLocations(filters: MasterLocationKeywordFilter): Observable<MasterListDto[]> {
        return this.apiService.post(`${this.wso2AmEndpoint}/app/master/get-mst-location-datas`, filters);
    }

    checkUser(email: string): Observable<UserStatus> {
        return this.apiService.post(`${this.wso2AmEndpoint}/app/checkUser/${email}`);
    }

    getUserData(userId: string): Observable<GetUserProfileModel> {
        return this.apiService.get(`${this.wso2AmEndpoint}/user-profile/user-data/${userId}`);
    }
}
