import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: any, args?: any[]): any {
    if (value.indexOf("http") >= 0 || value.indexOf("www") >= 0) {
      const link = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+~#?&//=]*)?(\[.*\])?/)
      if (link) {
        if (value.indexOf("http") >= 0 ) {
          const valueSplit = link[0].split('[')
          value = value.replace(link[0],
            "<a href='" + valueSplit[0] + "'>" +
            (valueSplit[1] ? valueSplit[1].slice(0, -1) : valueSplit[0]) +
            "</a>")
        }
        else {
          const valueSplit = link[0].split('[')
          value = value.replace(link[0],
            "<a href='" + "https://" + valueSplit[0] + "'>" +
            (valueSplit[1] ? valueSplit[1].slice(0, -1) : valueSplit[0]) +
            "</a>")
        }
      }
    }

    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
