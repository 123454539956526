import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { PagedResultDto } from '../../core/models/PagedResultDto';
import { UserConnectionInput, UserConnectionList } from '../models/network/user-connection-list';
import { DepartmentCount, UserDepartment } from '../models/network/department-count';
import { InviteConnection } from '../models/network/invite-connection';
import { ValidateInvite } from '../models/network/validate-invite';
import { CreateUserDto } from '../../core/models/user/CreateUserDto';
import { Department } from '../models/network/department';
import { MyCircleAnalytics } from '../models/network/my-circle-analysis';
import { AwardedBidderChartModel } from "../models/network/awarded-bidder-chart-model";
import { InviteUserModel } from '../models/network/invite-user.model';
import { AppConsts } from 'src/core/AppConsts';
import { MyCircleListFilter, MyCircleListModel } from '../models/network/my-circle-list-model';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {


  private remoteServiceBaseUrl: string;
  private registerEnpoint: string;
  constructor(
    private apiService: ApiService
  ) {

    this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
    this.registerEnpoint = AppConsts.userServiceBaseUrl;
  }

  list(input : UserConnectionInput): Observable<PagedResultDto<UserConnectionList>> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/userConnection`,input);
  }

  cloudchaluserlist(input: MyCircleListFilter): Observable<any> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/userConnection/CloudChalConnection`, input);
  }

  getDepartmentCount(userConnectionId: string): Observable<DepartmentCount[]> {
    return this.apiService.get(`${this.remoteServiceBaseUrl}/userConnection/getDepartmentCount?userConnectionId=${userConnectionId}`);
  }

  inviteUser(inviteId: string): Observable<InviteConnection> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/inviteUser/${inviteId}`);
  }

  validate(input: ValidateInvite): Observable<boolean> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/validateToken/${input.inviteId}?token=${input.token}`);
  }

  registerUserConnection(input: CreateUserDto): Observable<CreateUserDto> {
    return this.apiService.post(`${this.registerEnpoint}/app/register-user-connection/${input.inviteId}/token/${input.token}`, input);
  }

  addDepartments(userConnectionId: string, input: Department): Observable<Department> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/userConnection/department/${userConnectionId}`, input);
  }

  getDepartments(userConnectionId: string): Observable<UserDepartment[]> {
    return this.apiService.get(`${this.remoteServiceBaseUrl}/userConnection/department/${userConnectionId}`);
  }

  importExcel(file: File): Observable<InviteConnection[]> {
    let formData = new FormData();
    formData.append('file', file);
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/importExcel`, formData);
  }

  analytic(): Observable<MyCircleAnalytics> {
    return this.apiService.get(`${this.remoteServiceBaseUrl}/inviteConnection/circleAnaytic`);
  }

  getAwardedBidder(): Observable<AwardedBidderChartModel[]> {
    return this.apiService.get(`${this.remoteServiceBaseUrl}/inviteConnection/awardedBidder`);
  }

  invitedUserList(): Observable<PagedResultDto<InviteConnection>> {
    return this.apiService.get(`${this.remoteServiceBaseUrl}/inviteConnection/inviteConnetionList`);
  }

  userProfile(email: string): Observable<any>{
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/userProfile/${email}`);
  }

  usersProfile(): Observable<any>{
    return this.apiService.get(`${this.remoteServiceBaseUrl}/inviteConnection/userProfile`);
  }

  userProfileDetails(id: string): Observable<any>{
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/userProfileDetails/${id}`);
  }

  acceptUserConnection(inviteId: string, token: string): Observable<any> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/userConnection/${inviteId}/token/${token}`);
  }

  removeInvite(id: string): Observable<any> {
    return this.apiService.delete(`${this.remoteServiceBaseUrl}/inviteConnection/${id}`);
  }

  removeUserConnection(id: string): Observable<any> {
    return this.apiService.delete(`${this.remoteServiceBaseUrl}/userConnection/${id}`);
  }

  checkInviteUser(email: string): Observable<InviteUserModel> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/CheckInviteUser/${email}`);
  }
  invitedUser(email: string): Observable<any> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/${email}`);
  }

  mycircle(input: MyCircleListFilter): Observable<any> {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/userConnection/mycircle`, input);
  }

  getInvitedUserNotificationList(filter: MyCircleListFilter) {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/inviteConnection/invited-user-notificationlist`, filter);
  }

  addUserConnection(inviteId:string,token:string) {
    return this.apiService.post(`${this.remoteServiceBaseUrl}/userConnection/${inviteId}/token/${token}`);
  }
}
