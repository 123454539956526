<div>
    <h4 class="dialog-headline">Change Password
        <span>
            <mat-icon class="pull-right close-icon" (click)="onCloseDialog()">close</mat-icon>
        </span>
    </h4>

</div>
<hr>
<form [formGroup]="changePasswordForm" (ngSubmit)="onFormSubmit()">

    <div class="content-container">
        <div class="form-row">
            <label for="inputEmail4">Current Password</label><span class="text-red">*</span>
            <input type="password" class="form-control create-pro " formControlName="currentPassword" required>
            <div *ngFor="let validation of validationMessages['currentPassword']">
                <span class="text-danger"
                    *ngIf="formControls.currentPassword.hasError(validation.type) && (submitted || formControls.currentPassword.dirty || formControls.currentPassword.touched)">
                    {{validation.message}}</span>
            </div>
        </div>
        <div class="form-row">
            <label for="inputEmail4">New Password</label><span class="text-red">*</span>
            <input type="password" class="form-control create-pro " formControlName="newPassword"
                [type]="hide ? 'password' : 'text'" required>
            <div *ngFor="let validation of validationMessages['newPassword']">
                <span class="text-danger"
                    *ngIf="formControls.newPassword.hasError(validation.type) && (submitted || formControls.newPassword.dirty || formControls.newPassword.touched)">
                    {{validation.message}}</span>
            </div>
        </div>
        <div class="form-row">
            <label for="inputEmail4">Confirm Password</label><span class="text-red">*</span>
            <input type="password" class="form-control create-pro " formControlName="confirmPassword"
                [type]="confirmhide ? 'password' : 'text'" required>
            <div *ngFor="let validation of validationMessages['confirmPassword']">
                <span class="text-danger"
                    *ngIf="formControls.confirmPassword.hasError(validation.type) && (submitted || formControls.confirmPassword.dirty || formControls.confirmPassword.touched)">
                    {{validation.message}}</span>
            </div>
            <mat-error *ngIf="submitted && formControls.confirmPassword.errors && formControls.confirmPassword.errors.mustMatch">
                Password must match
            </mat-error>
        </div>
    </div>
    <hr>
    <!-- <mat-dialog-actions>
        <div class="col-lg-12 col-md-12 p-0">
            <span class="pull-right">
                <button mat-raised-button color="primary" type="submit" (click)="onCloseDialog()"
                    style="margin-left: 20px;">Cancel
                </button>
                <button type="submit" class="btn btn-default">Save</button>
            </span>
        </div>
    </mat-dialog-actions> -->
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close style="color: #575757 !important;
        padding: 0px 16px!important;
        background-color: #ffffff !important;
        border-color: #d7d7d7 !important;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%) !important; border:solid 1px;">Cancel</button>
        <button type="submit" mat-button style="background: #16c79a;color: #fff;">Save</button>
    </mat-dialog-actions>
</form>