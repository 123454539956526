import { GenderEnum } from "src/shared/enum/GenderEnum";
import { IdCardTypeEnum } from "src/shared/enum/IdCardTypeEnum";

export class UserProfileModel {
    name: string;
    email: string;
    contactNo: string;
    age: number;
    aadharNo: string;
    designationId: string;
    educationLevelId: string;
    expId: string;
    currencyId: string;
    languageId: string;
    dateFormatId: string;
    timeZoneId: string;
    countryId: string;
    stateId: string;
    cityId: string;
    profile: File;
    workCategories: UserWorkCategoryModel[] = [];
    surname: string;
    gender: GenderEnum;
    idCardType: IdCardTypeEnum;
    cardNumber: string;
    about: string;
    addressLine1:string;
    companyName: string;
}
export class UserWorkCategoryModel {
    id: string;
    name: string;
}