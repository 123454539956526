import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert } from 'src/core/models/alert.model';
import { AlertService } from 'src/core/services/alert.service';

@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html'
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private alertService: AlertService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.alertService.onAlert(this.id)
      .subscribe(alert => {

        this.snackBar.open(alert.message, null, {
          duration: 5 * 1000,
          horizontalPosition: 'right',
          panelClass: alert.panelClass
        });
      });
  }

  ngOnDestroy() {
  }
}