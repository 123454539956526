import { MasterDataDto } from "src/core/models/master/MasterDataDto";
import { PagedAndSortedResultRequestModel } from "src/core/models/PagedResultDto";

export class MyCircleListModel {
    id: string;
    profilePath: string;
    name: string;
    surname: string;
    country: string;
    state: string;
    city: string;
    totalUserConnection: number;
    totalProject: number;
    activeProject: number;
    closedProject: number;
    totalBid: number;
    inProgressBid: number;
    assignedBid: number;
    completedBid: number;
    category: string;
    userWorkCategory: MasterDataDto[] = [];
    buttonStatus:string;
}

export class MyCircleListFilter extends PagedAndSortedResultRequestModel {
    keyword: string;
    workCategory: string;
    organizationSizes: MasterDataDto[];
    location: string;
}