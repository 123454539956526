import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CreateUserDto } from '../models/user/CreateUserDto';
import { ApiService } from './api.service';
import {AppConsts} from '../AppConsts';
import { RequestDemoModel } from 'src/shared/models/account/request-demo.model';
import { ResponseModel } from '../models/response/response.model';
import { ChangePasswordModel } from 'src/shared/models/account/change-password.model';
import { UserStatus } from 'src/shared/models/account/user-status.enum';
import { ResetPasswordModel } from 'src/shared/models/account/reset-password.model';
import { CreateUserDetailDto } from 'src/shared/models/user-list/CreateUserDetailDto';
import { ValidateInvite } from 'src/shared/models/network/validate-invite';
@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    constructor(
            private apiService: ApiService
            
        ) {

            this.registerEnpoint = AppConsts.userServiceBaseUrl+"/app";
            
    }
    private serviceEndpoint = AppConsts.remoteServiceBaseUrl;
    private wso2amEndpoint = AppConsts.userServiceBaseUrl;
    registerEnpoint: string;

    registerUser(createUserDto: CreateUserDto): Observable<CreateUserDto> {
        const url = this.registerEnpoint + `/app/register-user`;
        return this.apiService.post(url, createUserDto);
    }
    requestDemo(requestDemo: RequestDemoModel): Observable<ResponseModel> {
        return this.apiService.post(`${this.serviceEndpoint}/app/register-user/contact-us`, requestDemo);
    }
    
    changePassword(changePasswordModel : ChangePasswordModel): Observable<any> {
        return this.apiService.post(`${this.registerEnpoint}/register-user/change-password`,changePasswordModel);
    }
    resetPasswordGet(email : string): Observable<ResponseModel> {
        return this.apiService.get(`${this.registerEnpoint}/register-user​/reset-password?email=${email}`);
    }

    resetPassword(resetPasswordModel : ResetPasswordModel): Observable<ResponseModel> {
        return this.apiService.post(`${this.registerEnpoint}​/register-user​/reset-password`,resetPasswordModel);
    }

    checkUser(email : string): Observable<UserStatus> {
        return this.apiService.post(`${this.registerEnpoint}/checkUser/${email}`);
    }
    registerNewUser(createUserDto: CreateUserDto): Observable<any> {
        const url = this.registerEnpoint + `/register-user/register-user`;
        return this.apiService.post(url, createUserDto);
    }

    registerUserConnection(input: CreateUserDto): Observable<any> {
        return this.apiService.post(`${this.registerEnpoint}/register-user-connection/${input.inviteId}/token/${input.token}`, input);
    }
    registerDetails(userId: string | undefined, createUserDetailDto: CreateUserDetailDto = new CreateUserDetailDto()) {
        const formData = new FormData();
        formData.append("businessTypeId", createUserDetailDto.businessTypeId);
        formData.append("userDocument.cstNo", createUserDetailDto.userDocument.cstNo);
        formData.append("userDocument.panNo", createUserDetailDto.userDocument.panNo);
        formData.append("userDocument.serviceTaxNo", createUserDetailDto.userDocument.serviceTaxNo);
        formData.append("userDocument.vatTinNo", createUserDetailDto.userDocument.vatTinNo);
        for (const key of Object.keys(createUserDetailDto)) {
            if (key === 'balanceSheets') {
                for (const i of createUserDetailDto[key]) {
                    formData.append(key, i);
                }
            }
        }

        const url = this.registerEnpoint + `/register-user/register-details/${userId}`;
        return this.apiService.post(url, formData);
    }
    validate(input: ValidateInvite): Observable<boolean> {
        return this.apiService.post(`${this.registerEnpoint}/validateToken/${input.inviteId}?token=${input.token}`);
    }

}
