import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ChangePasswordDailogComponent } from 'src/app/change-password-dailog/change-password-dailog.component';
import { AppComponentBase } from 'src/core/app-component-base';
import { AppConsts } from 'src/core/AppConsts';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { PermissionService } from 'src/core/services/permission.service';
import { Permission } from 'src/shared/enum/permission.enum';
import { MyCircleListFilter } from 'src/shared/models/network/my-circle-list-model';
import { UserConnectionInput } from 'src/shared/models/network/user-connection-list';
import { NetworkService } from 'src/shared/services/network.service';
import { UserProfileService } from 'src/shared/services/userProfile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends AppComponentBase implements OnInit {

  isUserAdmin: boolean;
  isAdmin: boolean;
  keyword: any;
  profileLogo: any;
  currentUserName: string;
  status: any;
  invitedUserList: any;
  rolePermission: any;
  project: boolean;
  bid: boolean;
  workOrder: boolean;
  invoice: boolean;
  user: boolean;
  userManage: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private userProfileService: UserProfileService,
    public dialog: MatDialog,
    private injector: Injector,
    private networkService: NetworkService,
    private snackBar: MatSnackBar,
    public permissionService: PermissionService,
    private router: Router) { super(injector); }

  ngOnInit(): void {
    this.getRolePermission();
    this.getProfile();
    this.invitedUser();
  }
  getProfile() {
    this.userProfileService.get()
      .subscribe(
        res => {
          this.currentUserName = res.name;
          this.profileLogo = res.profile;
          this.userProfileService.checkUser(res.email)
            .subscribe((res: any) => {
              this.status = res;
            });
        });
  }


  onLogout() {
    localStorage.removeItem("userInfo");
    this.authenticationService.signout();
  }
  onChangePassowrd() {
    let dialogRef = this.dialog.open(ChangePasswordDailogComponent, {
      width: '500px',
      height: '420px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  onSubmit() {
    this.router.navigate(['/app/network', { keyword: this.keyword }]);
  }
  networkList(keyword): void {
    const request: UserConnectionInput = new UserConnectionInput();
    request.keyword = keyword;
    this.networkService.list(request).subscribe(res => {
      this.router.navigate(['/app/network']);
    });
  }

  invitedUser() {
    let request = new MyCircleListFilter();
    this.networkService.getInvitedUserNotificationList(request)
      .subscribe(res => {
        this.invitedUserList = res.items;
      });
  }

  addUserConnection(inviteId: string, token: string) {
    this.networkService.addUserConnection(inviteId, token)
      .subscribe(
        res => {
          this.snackBar.open("Thank you for accepting the request.", null, {
            duration: 5 * 1000,
            horizontalPosition: 'right',
            panelClass: 'success-snackbar'
          });
          this.invitedUser();
        },
        err => {
          this.snackBar.open(err.error.error.message, null, {
            duration: 5 * 1000,
            horizontalPosition: 'right',
            panelClass: 'error-snackbar'
          });
        });
  }

  getRolePermission() {
    this.permissionService.getRolePermission()
      .subscribe(res => {
        this.rolePermission = res;
        this.project = this.rolePermission?.find(({ permissionName }) => permissionName === Permission.ProjectModuleProjectList) ? true : false;
        this.bid = this.rolePermission?.find(({ permissionName }) => permissionName === Permission.BidModuleBidList) ? true : false;
        this.workOrder = this.rolePermission?.find(({ permissionName }) => permissionName === Permission.WorkOrderModuleWorkOrderList) ? true : false;
        this.invoice = this.rolePermission?.find(({ permissionName }) => permissionName === Permission.InvoiceModuleInvoiceList) ? true : false;
        this.user = (this.rolePermission?.find(({ permissionName,role }) => (permissionName === Permission.AbpIdentityUsersList) && (role?.name === 'admin'))) ? true : false;
        this.userManage = (this.rolePermission?.find(({ permissionName,role }) => (permissionName === Permission.AbpIdentityUsersList) && (role?.name === 'DeveloperAdmin' || role?.name ==='ContractorAdmin'))) ? true : false;
      });
  }
}
