export class UserConnectionList {
  id: string;
  name: string;
  email:string;
  userId: string;
  profile:string;
  surname: string;
}

export class UserConnectionInput {
  keyword : string; 
}
