import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BidDto } from '../models/bid/BidDto';
import { CreateUserDto } from '../models/user/CreateUserDto';
import { ApiService } from './api.service';
import { BidFilter } from '../models/bid/bid.filter';
import { BidListComponentModel, BidListDto } from '../models/bid/bid.list';
import { PagedResultDto } from '../models/PagedResultDto';
import { BidModel, CreateBidModel } from '../../shared/models/bid/createbid.model';
import { AppConsts } from '../AppConsts';
import { BidListComponent } from 'src/app/project/bid/bid-list/bid-list.component';
import { BidDetailModel } from '../models/bid/bid.details';
import { ResponseModel } from '../models/response/response.model';
import { BidderListModel } from 'src/shared/models/bidderList/bidder-list.model';
import { BidderFilter } from '../models/bid/bidder.filter';
import { Createbiddetaildto } from 'src/shared/models/bid/createbiddetaildto';
import { Getbiddetaildto } from 'src/shared/models/bid/getbiddetaildto';
import { ChangeBidStatusModel } from 'src/shared/models/bid/changebidstatus.model';
import { InviteStatusFilter } from 'src/shared/models/bid/invitestatusfilter';
import { MasterDataDto } from '../models/master/MasterDataDto';

@Injectable({
  providedIn: 'root'
})
export class BidService {
  constructor(
    private apiService: ApiService
  ) {


  }

  private wso2amEndpoint = AppConsts.remoteServiceBaseUrl + "/app";


  getWorkOrders(bidId: string): Observable<Getbiddetaildto> {
    return this.apiService.get(`${this.wso2amEndpoint}/bid/${bidId}/get-workOrders`);
  }

  list(filter: BidFilter): Observable<BidListComponentModel> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/get-bids-by-status`, filter);
  }

  saveBid(createBidModel: CreateBidModel): Observable<any> {
    const formData = new FormData();
    for (const key of Object.keys(createBidModel)) {
      if (typeof (createBidModel[key]) === 'object') {
        if (key === 'createBidDetails') {
          for (let i = 0; i < createBidModel[key].length; i++) {
            formData.append('createBidDetails[' + i + '].typeId', createBidModel[key][i].typeId);
            formData.append('createBidDetails[' + i + '].description', createBidModel[key][i].description == null ? '' : createBidModel[key][i].description);
            formData.append('createBidDetails[' + i + '].quantity', createBidModel[key][i].quantity);
            formData.append('createBidDetails[' + i + '].remarks', createBidModel[key][i].remarks);
            formData.append('createBidDetails[' + i + '].title', createBidModel[key][i].title == null ? '' : createBidModel[key][i].title);
            formData.append('createBidDetails[' + i + '].uom', createBidModel[key][i].uom);
          }
        }
        else if (key === 'createBidCheckList') {
          for (let i = 0; i < createBidModel[key].length; i++) {
            formData.append('createBidCheckList[' + i + '].description', createBidModel[key][i].description == null ? '' : createBidModel[key][i].description);
            formData.append('createBidCheckList[' + i + '].mandatory', createBidModel[key][i].mandatory);
            formData.append('createBidCheckList[' + i + '].checkListId', createBidModel[key][i].checkListId == null ? '' : createBidModel[key][i].checkListId);
          }
        }
        else if (key === 'boqFile' || key === 'drawingFile' || key === 'gccFile' || key === 'sccFile' || key === 'qualityManualFile' || key === 'safetyManualFile') {
          for (const i of createBidModel[key]) {
            formData.append(key, i);
          }
        }
      }
      else {
        formData.append(key, createBidModel[key]);
      }
    }
    return this.apiService.post(`${this.wso2amEndpoint}/bid/saveBid`, formData);
  }

  updateBid(bidId: string, createBidModel: CreateBidModel): Observable<any> {
    const formData = new FormData();
    for (const key of Object.keys(createBidModel)) {
      if (typeof (createBidModel[key]) === 'object') {
        if (key === 'createBidDetails') {
          for (let i = 0; i < createBidModel[key].length; i++) {
            formData.append('createBidDetails[' + i + '].id', createBidModel[key][i].id);
            formData.append('createBidDetails[' + i + '].typeId', createBidModel[key][i].typeId);
            formData.append('createBidDetails[' + i + '].description', createBidModel[key][i].description == null ? '' : createBidModel[key][i].description);
            formData.append('createBidDetails[' + i + '].quantity', createBidModel[key][i].quantity);
            formData.append('createBidDetails[' + i + '].remarks', createBidModel[key][i].remarks);
            formData.append('createBidDetails[' + i + '].title', createBidModel[key][i].title == null ? '' : createBidModel[key][i].title);
            formData.append('createBidDetails[' + i + '].uom', createBidModel[key][i].uom);
          }
        }
        else if (key === 'createBidCheckList') {
          for (let i = 0; i < createBidModel[key].length; i++) {
            formData.append('createBidCheckList[' + i + '].id', createBidModel[key][i].id);
            formData.append('createBidCheckList[' + i + '].description', createBidModel[key][i].description == null ? '' : createBidModel[key][i].description);
            formData.append('createBidCheckList[' + i + '].mandatory', createBidModel[key][i].mandatory);
            formData.append('createBidCheckList[' + i + '].checkListId', createBidModel[key][i].checkListId == null ? '' : createBidModel[key][i].checkListId);
          }
        }
        else if (key === 'boqFile' || key === 'drawingFile' || key === 'gccFile' || key === 'sccFile' || key === 'qualityManualFile' || key === 'safetyManualFile') {
          for (const i of createBidModel[key]) {
            formData.append(key, i);
          }
        }
      }
      else {
        formData.append(key, createBidModel[key]);
      }
    }
    return this.apiService.post(`${this.wso2amEndpoint}/bid/${bidId}/update`, formData);
  }

  saveBidResponse(bidId: string, createBidModel: Createbiddetaildto): Observable<Createbiddetaildto> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/${bidId}/saveBidResponse`, createBidModel);
  }

  submitBidResponse(bidId: string): Observable<Createbiddetaildto> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/${bidId}/submitBidResponse`,);
  }

  getBid(id: string): Observable<CreateBidModel> {
    return this.apiService.get(`${this.wso2amEndpoint}/bid?bidId=${id}`);
  }

  getDetails(bidId: string): Observable<BidDetailModel> {
    return this.apiService.get(`${this.wso2amEndpoint}/project/project-bid-details-by-bid-id/${bidId}`);
  }

  inviteBidders(userId: string, bidId: string): Observable<ResponseModel> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/${bidId}/inviteBidders?userId=${userId}`);
  }

  getBidders(filter: BidderFilter, bidId: string): Observable<PagedResultDto<BidderListModel>> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/${bidId}/getBidders`, filter);
  }
  acceptOrReject(bidId: string, inviteStatusFilter: InviteStatusFilter): Observable<ResponseModel> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/${bidId}/inviteResponse`, inviteStatusFilter);
  }

  changebidstatus(changeBidStatusModel: ChangeBidStatusModel) {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/change-bid-status`, changeBidStatusModel)
  }

  getBidListData(projectId: string): Observable<MasterDataDto[]> {
    if (projectId != null) {
      return this.apiService.get(`${this.wso2amEndpoint}/bid/bid-list?projectId=${projectId}`)
    }
    else {
      return this.apiService.get(`${this.wso2amEndpoint}/bid/bid-list`)
    }
  }

  getBidTemplateList(): Observable<MasterDataDto[]> {
    return this.apiService.post(`${this.wso2amEndpoint}/bid/bid-template-list`)
  }
}
