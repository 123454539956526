<nav class="navbar navbar-dark navbar-expand-md bg-success justify-content-between header fixed-top p-0" [style.background-color]="user ? '#ffff !important': '#ffff !important'" style="line-height: 1;">
    <div class="container">
        <div class=" mr-0 p-0 navbar-collapse" href="#"  style="width: 245px;">
            <img alt="CloudChal logo" class="header-brand-img" routerLinkActive="active" routerLink="/app/home" src="assets/images/cloudchalLogo.png" style="height: 60px;">
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-nav">
            <span class="fa fa-bars"></span>
        </button>
        <div class="navbar-collapse collapse dual-nav ml-5 w-100">
            <ul class="navbar-nav">
                <!-- <li class="nav-item  d-block" routerLinkActive="active">
                     <p class="mb-0"><i class="fe fe-home ml-1"></i></p>
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/home">Home</a>
                </li> -->
                <!-- <li class="nav-item d-block" routerLinkActive="active"> -->
                <!-- <p class="mb-0"><i class="fe fe-layers ml-3"></i><span class="unread-not">2</span></p> -->

                <!-- <a class="nav-link pl-0" data-toggle="dropdown" routerLink="/app/project">Projects </a> -->
                <!-- <div class="dropdown">
                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                            <a class="dropdown-item d-flex pb-3" href="/app/dashboard">
                                <div>
                                    <strong>Dashboard</strong>
                                </div>
                            </a>
                            <a class="dropdown-item d-flex pb-3"  href="/app/project">
                                <div>
                                    <strong>Project</strong>
                                </div>
                            </a>
                            <a class="dropdown-item d-flex pb-3" *ngIf="isDeveloper" href="/app/project/bid">
                                <div>
                                    <strong>Bid</strong>
                                </div>
                            </a> -->
                <!-- <a class="dropdown-item d-flex pb-3" href="/app/project/createWorkOrder">
                                <div>
                                    <strong>WorkOrder</strong>
                                </div>
                            </a> -->
                <!-- <a class="dropdown-item d-flex pb-3" *ngIf="isDeveloper" href="/app/project/invoice">
                                <div>
                                    <strong>Invoice</strong>
                                </div>
                            </a> -->
                <!-- <a class="dropdown-item d-flex pb-3" href="/app/project/rfilist">
                                <div>
                                    <strong>RFI-List</strong>
                                </div>
                            </a>
                            <a class="dropdown-item d-flex pb-3" href="/app/project/comparative-statement">
                                <div>
                                    <strong>Comparative statement</strong>
                                </div>
                            </a> -->
                <!-- </div>
                    </div> -->
                <!-- </li> -->
                <li class="nav-item  d-block  mx-3" routerLinkActive="active">
                    <!-- <p class="mb-0"><i class="fe fe-home ml-1"></i></p> -->
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/home"> Feed </a>
                </li>

                <li class="nav-item  d-block  mx-3" routerLinkActive="active">
                    <!-- <p class="mb-0"><i class="fe fe-home ml-1"></i></p> -->
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/job"> Jobs </a>
                </li>

                <li class="nav-item  d-block  mx-3" routerLinkActive="active">
                    <!-- <p class="mb-0"><i class="fe fe-home ml-1"></i></p> -->
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/network/mycircle"> Circle </a>
                </li>
                <li class="nav-item li-line" routerLinkActive="active">
                    <a class="nav-link pl-0" routerLinkActive="active">  | </a>
                </li>
                <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="project">
                    <!-- <p class="mb-0"><i class="fe fe-home ml-1"></i></p> -->
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/project">  Projects </a>
                </li>
                <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="bid">
                    <!-- <p class="mb-0"><i class="fe fe-home ml-1"></i></p> -->
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/bids">  Bids </a>
                </li>
                <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="workOrder">
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/workorders"> WorkOrders </a>
                </li>
                <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="invoice">
                    <!-- <p class="mb-0"><i class="fe fe-home ml-1"></i></p> -->
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/invoices"> Invoices </a>
                </li>

                <!-- <li class="nav-item  d-block  mx-3" routerLinkActive="active">
                    <a *ngIf="this.status==1" class="nav-link pl-0" routerLinkActive="active" routerLink="/app/document"> Documents </a>
                    <a *ngIf="this.status!=1" class="nav-link disabled pl-0" routerLinkActive="disable"> Documents </a>
                </li> -->
                <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="user">
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/user">User</a>
                </li>

                <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="user">
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/packages">Subscription</a>
                </li>
                <li class="nav-item  d-block mx-3" routerLinkActive="active">
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/home/new-dashboard">Dashboard</a>
                </li>
                <!-- <li class="nav-item  d-block  mx-3" routerLinkActive="active" *ngIf="isAdmin">
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/department">Department</a>
                </li> -->
                <!-- <li class="nav-item  d-block" routerLinkActive="active">
                     <p class="mb-0"><i class="fe fe-home ml-1"></i></p>
                    <a class="nav-link pl-0" routerLinkActive="active" routerLink="/app/setting">Setting</a>
                </li> -->
                <!-- <li class="nav-item  d-block">
                    <p class="mb-0"><i class="fe fe-users ml-4"></i><span class="unread-not">2</span></p>
                    <a class="nav-link pl-0" href="network.html">Network</a>
                </li>
                <li class="nav-item  d-block">
                    <p class="mb-0"><i class="fe fe-briefcase ml-0"></i><span class="unread-not">2</span></p>
                    <a class="nav-link pl-0" href="jobs.html">Jobs</a>
                </li>
                <li class="nav-item  d-block">
                    <p class="mb-0"><i class="fe fe-mail ml-4"></i><span class="unread-not">2</span></p>
                    <a class="nav-link pl-0" href="messages.html">Messages</a>
                </li>
                <li class="nav-item  d-block">
                    <p class="mb-0"><i class="fe fe-bell ml-5"></i><span class="unread-not">2</span></p>
                    <a class="nav-link pl-0" href="#">Notification</a>
                </li> -->
            </ul>
        </div>

        <div class="navbar-collapse collapse dual-nav w-100">
            <div class="nav navbar-nav ml-auto">
                <div class="dropdown" style="margin-top: 5px;" *ngIf="invitedUserList?.length != 0">
                    <a class="nav-link mt-2 leading-none d-flex" data-toggle="dropdown" href="#">
                        <i class="fa fa-user-plus icon-blue"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left dropdown-menu-arrow" style="min-width: 300px !important;">
                        <div class="row" *ngFor="let invitedUser of invitedUserList">
                            <div class="col-md p-1">
                                <img *ngIf="invitedUser.invitedByUserProfilePath" class="media-object avatar brround avatar-sm bg-light" src="{{invitedUser.invitedByUserProfilePath}}">
                                <img *ngIf="!invitedUser.invitedByUserProfilePath" class="media-object avatar brround avatar-sm bg-light" src="assets/images/profile1.png">
                                <span class="p-1">{{invitedUser.invitedByUserName}} {{invitedUser.invitedByUserSurname}}</span>
                                <button type="button" class="btn btn-primary btn-sm pull-right" (click)="addUserConnection(invitedUser.inviteId,invitedUser.token)">Accpted</button>
                            </div>
                        </div>
                    </div>
                </div>
                <li class="nav-item  d-block" routerLinkActive="active">
                    <i class="nav-link fa fa-envelope icon-blue mt-2" routerLinkActive="active" routerLink="/app/message"></i>
                </li>
                <li class="nav-item  d-block" routerLinkActive="active">
                    <i class="nav-link fa fa-bell icon-blue mt-2" routerLinkActive="active" routerLink="/app/notification"></i>
                </li>


                <!-- <div class="nav-search">
                    <form class="input-icon">
                        <input class="form-control header-earch mt-2" type="text" placeholder="You can search person" width="300px" [(ngModel)]="keyword" name="keyword">
                        <div class="input-icon-addon">
                            <i class="fe fe-search" (click)="onSubmit()"></i>
                            <button type="submit" class="d-none" (click)="onSubmit()"></button>
                        </div>
                    </form> -->
                <!--  placeholder="You can search job, person, organization here" tabindex="1"
                        <form [formGroup]="inviteForm" (ngSubmit)="onInviteSubmit()" style="width: 100%;">
                        <div class="card p-4 contact-wrapper">
                            <div class="d-flex">

                                <input class="form-control" type="text" formControlName="email" required>&nbsp;
                                <div *ngFor="let validation of validationMessages['email']">
                                    <span class="text-danger" *ngIf="inviteFormControls.email.hasError(validation.type) && (submitted || inviteFormControls.email.dirty || inviteFormControls.email.touched)">
                        {{validation.message}}</span>
                                </div>
                                <button class="btn btn-primary btn-sm" type="submit" style="padding:4px 29px 4px 23px;">Invite</button>

                            </div>
                        </div>
                    </form> -->
                <!-- </div> -->
                <div class="dropdown">
                    <a class="nav-link pr-0 pl-1 leading-none d-flex " data-toggle="dropdown" href="#">
                        <h4 class="nav-link  mt-2">{{this.currentUserName}}</h4>
                        <img *ngIf="profileLogo" class="avatar avatar-md mr-2 brround" src="{{profileLogo}}">
                        <img *ngIf="!profileLogo" class="avatar avatar-md mr-2 brround" src="assets/images/profile1.png">

                        <i class="fa fa-angle-down ml-1"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <!-- <a class="dropdown-item" href="profile.html"><i class="dropdown-icon mdi mdi-account-outline"></i> Profile</a>
    <a class="dropdown-item" href="#"><i class="dropdown-icon mdi mdi-settings"></i> Settings</a>
    <a class="dropdown-item" href="#"><span class="float-right"><span class="badge badge-primary">6</span></span> <i class="dropdown-icon mdi mdi-message-outline"></i> Inbox</a>
    <a class="dropdown-item" href="#"><i class="dropdown-icon mdi mdi-comment-check-outline"></i> Message</a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#"><i class="dropdown-icon mdi mdi-compass-outline"></i> Need help?</a> -->
                        <a class="dropdown-item" routerLink="/app/profile"><i
                           class="dropdown-icon mdi mdi-account"></i> Profile </a>
                        <a class="dropdown-item" routerLink="/app/setting/profile">
                            <i class="dropdown-icon mdi mdi-settings"></i> Setting </a>
                        <a class="dropdown-item" routerLink="/app/user" *ngIf="userManage">
                            <i class="dropdown-icon mdi mdi-account-multiple"></i> Manage User</a>
                        <a class="dropdown-item" routerLink="/app/subscription/plan" *ngIf="userManage">
                            <i class="dropdown-icon mdi mdi-account-multiple"></i> Subscription Plan</a>
                        <a class="dropdown-item" style="cursor: pointer;" (click)="onLogout()">
                            <i class="dropdown-icon mdi mdi-logout-variant"></i> Sign out</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
