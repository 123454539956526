import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, timer } from 'rxjs';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppConsts } from '../AppConsts';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    // As in OAuthConfig.
    public storage: Storage = localStorage;

    /**
     * Stores the URL so we can redirect after signing in.
     */
    public redirectUrl: string;

    /**
     * Behavior subjects of the user's status & data.
     */
    private signinStatus = new BehaviorSubject<boolean>(false);
    private user = new BehaviorSubject<any>(null);

    /**
     * Scheduling of the refresh token.
     */
    private refreshSubscription: any;

    /**
     * Offset for the scheduling to avoid the inconsistency of data on the client.
     */
    private offsetSeconds = 30;

    constructor(
        private router: Router,        
        private oAuthService: OAuthService,
        // private cookieService: CookieService,
        private httpClient: HttpClient
    ) {
        if (this.oAuthService.hasValidAccessToken()) {
            this.init();
            /*this.scheduleRefresh();*/
        }
    }

    public init(): void {
        // Tells all the subscribers about the new status & data.
        this.signinStatus.next(true);
        this.user.next(this.getUser());
    }

    public signout(): void {
        this.oAuthService.logOut();
        
        this.redirectUrl = null;

        // Tells all the subscribers about the new status & data.
        this.signinStatus.next(false);
        this.user.next(null);

        // Unschedules the refresh token.
        this.unscheduleRefresh();

        this.router.navigate(['home']);
        // this.cookieService.deleteAll('/','localhost');
        window.location.href=AppConsts.loginUrl+"/login";
    }


    public getToken(): string {
        if (this.isSignedIn()) {
            const token: string = this.oAuthService.getAccessToken();
            return token;
        }
        else {
            return null;
        }
    }

    public isSignedIn(): Observable<boolean> {
        return this.signinStatus.asObservable();
    }

    public isLogedIn(): boolean {
        return this.oAuthService.hasValidAccessToken();
    }
    public userChanged(): Observable<object> {
        return this.user.asObservable();
    }

    //  public isInRole(userType: string): boolean {
    //      const user: LoggedInUser = this.getUser();
    //      return user.userType == userType;
    //  }

    public getUser(): any {
        let user: any = null;
        if (this.oAuthService.hasValidAccessToken()) {
            const userInfo: any = this.oAuthService.getIdentityClaims();
            user = userInfo;
        }
        return user;
    }

    public getProfile(): Observable<any> {
        if (this.oAuthService.hasValidAccessToken()) {
            return this.httpClient.get(AppConsts.userServiceBaseUrl + "/identity/my-profile");
        }
    }

    /**
     * Strategy for refresh token through a scheduler.
     * Will schedule a refresh at the appropriate time.
     */
    public scheduleRefresh(): void {
        const source: Observable<number> = interval(
            this.calcDelay(this.getAuthTime())
        );

        this.refreshSubscription = source.subscribe(() => {
            this.oAuthService.refreshToken()
                .then(() => {
                    // Scheduler works.
                })
                .catch((error: any) => {
                    this.handleRefreshTokenError();
                });
        });
    }

    /**
     * Case when the user comes back to the app after closing it.
     */
    public startupTokenRefresh(): void {
        if (this.oAuthService.hasValidAccessToken()) {
            const source: Observable<number> = timer(this.calcDelay(new Date().valueOf()));

            // Once the delay time from above is reached, gets a new access token and schedules additional refreshes.
            source.subscribe(() => {
                this.oAuthService.refreshToken()
                    .then(() => {
                        this.scheduleRefresh();
                    })
                    .catch((error: any) => {
                        this.handleRefreshTokenError();
                    });
            });
        }
    }

    /**
     * Unsubscribes from the scheduling of the refresh token.
     */
    private unscheduleRefresh(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    /**
     * Handles errors on refresh token, like expiration.
     */
    private handleRefreshTokenError(): void {
        this.redirectUrl = this.router.url;

        // Tells all the subscribers about the new status & data.
        this.signinStatus.next(false);
        this.user.next(null);

        // Unschedules the refresh token.
        this.unscheduleRefresh();

        // The user is forced to sign in again.
        this.router.navigate(['/account/signin']);
    }

    private calcDelay(time: number): number {
        const expiresAt: number = this.oAuthService.getAccessTokenExpiration();
        const delay: number = expiresAt - time - this.offsetSeconds * 1000;
        return delay > 0 ? delay : 0;
    }

    private getAuthTime(): number {
        return parseInt(this.storage.getItem('access_token_stored_at'), 10);
    }
}
