export enum Permission {
    AbpIdentityRoles = "AbpIdentity.Roles",
    AbpIdentityRolesCreate = "AbpIdentity.Roles.Create",
    AbpIdentityRolesDelete = "AbpIdentity.Roles.Delete",
    AbpIdentityRolesManagePermissions = "AbpIdentity.Roles.ManagePermissions",
    AbpIdentityRolesUpdate = "AbpIdentity.Roles.Update",
    AbpIdentityUserLookup = "AbpIdentity.UserLookup",
    AbpIdentityUsers = "AbpIdentity.Users",
    AbpIdentityUsersCreate = "AbpIdentity.Users.Create",
    AbpIdentityUsersDelete = "AbpIdentity.Users.Delete",
    AbpIdentityUsersList = "AbpIdentity.Users.List",
    AbpIdentityUsersManagePermissions = "AbpIdentity.Users.ManagePermissions",
    AbpIdentityUsersUpdate = "AbpIdentity.Users.Update",
    BankModuleBank = "BankModule.Bank",
    BankModuleBankCreate = "BankModule.Bank.Create",
    BankModuleBankList = "BankModule.Bank.List",
    BankModuleBankUpdate = "BankModule.Bank.Update",
    BankModuleBankView = "BankModule.Bank.View",
    BidModuleBid = "BidModule.Bid",
    BidModuleBidApply = "BidModule.Bid.Apply",
    BidModuleBidCreate = "BidModule.Bid.Create",
    BidModuleBidList = "BidModule.Bid.List",
    BidModuleBidUpdate = "BidModule.Bid.Update",
    BidModuleBidView = "BidModule.Bid.View",
    BidModuleBidComparison = "BidModule.BidComparison",
    BidModuleBidComparisonAwarded = "BidModule.BidComparison.Awarded",
    BidModuleBidComparisonList = "BidModule.BidComparison.List",
    BidModuleBidComparisonView = "BidModule.BidComparison.View",
    BidModuleBidder = "BidModule.Bidder",
    BidModuleBidderInvite = "BidModule.Bidder.Invite",
    BidModuleBidderList = "BidModule.Bidder.List",
    BidModuleBidderView = "BidModule.Bidder.View",
    BidModuleBidFile = "BidModule.BidFile",
    BidModuleBidFileDelete = "BidModule.BidFile.Delete",
    BidModuleBidFileDownload = "BidModule.BidFile.Download",
    BidModuleBidFileList = "BidModule.BidFile.List",
    BidModuleBidFileReplace = "BidModule.BidFile.Replace",
    BidModuleBidFileUpload = "BidModule.BidFile.Upload",
    BidModuleBidFileView = "BidModule.BidFile.View",
    BidModuleBillOfQuantity = "BidModule.BillOfQuantity",
    BidModuleBillOfQuantityCreate = "BidModule.BillOfQuantity.Create",
    BidModuleBillOfQuantityList = "BidModule.BillOfQuantity.List",
    BidModuleBillOfQuantityUpdate = "BidModule.BillOfQuantity.Update",
    BidModuleBillOfQuantityView = "BidModule.BillOfQuantity.View",
    BidModuleRfi = "BidModule.Rfi",
    BidModuleRfiList = "BidModule.Rfi.List",
    BidModuleRfiRequest = "BidModule.Rfi.Request",
    BidModuleRfiResponse = "BidModule.Rfi.Response",
    BidModuleRfiView = "BidModule.Rfi.View",
    CertificateAndPortfolioModuleCertificateAndPortfolio = "CertificateAndPortfolioModule.CertificateAndPortfolio",
    CertificateAndPortfolioModuleCertificateAndPortfolioDelete = "CertificateAndPortfolioModule.CertificateAndPortfolio.Delete",
    CertificateAndPortfolioModuleCertificateAndPortfolioDownload = "CertificateAndPortfolioModule.CertificateAndPortfolio.Download",
    CertificateAndPortfolioModuleCertificateAndPortfolioList = "CertificateAndPortfolioModule.CertificateAndPortfolio.List",
    CertificateAndPortfolioModuleCertificateAndPortfolioReplace = "CertificateAndPortfolioModule.CertificateAndPortfolio.Replace",
    CertificateAndPortfolioModuleCertificateAndPortfolioUpload = "CertificateAndPortfolioModule.CertificateAndPortfolio.Upload",
    CertificateAndPortfolioModuleCertificateAndPortfolioView = "CertificateAndPortfolioModule.CertificateAndPortfolio.View",
    FeatureManagementManageHostFeatures = "FeatureManagement.ManageHostFeatures",
    InvoiceModuleInvoice = "InvoiceModule.Invoice",
    InvoiceModuleInvoiceAccept = "InvoiceModule.Invoice.Accept",
    InvoiceModuleInvoiceCreate = "InvoiceModule.Invoice.Create",
    InvoiceModuleInvoiceList = "InvoiceModule.Invoice.List",
    InvoiceModuleInvoiceReject = "InvoiceModule.Invoice.Reject",
    InvoiceModuleInvoiceView = "InvoiceModule.Invoice.View",
    JobModuleJob = "JobModule.Job",
    JobModuleJobApply = "JobModule.Job.Apply",
    JobModuleJobCreate = "JobModule.Job.Create",
    JobModuleJobDelete = "JobModule.Job.Delete",
    JobModuleJobList = "JobModule.Job.List",
    JobModuleJobUpdate = "JobModule.Job.Update",
    JobModuleJobView = "JobModule.Job.View",
    OrganizationModuleOrganization = "OrganizationModule.Organization",
    OrganizationModuleOrganizationCreate = "OrganizationModule.Organization.Create",
    OrganizationModuleOrganizationUpdate = "OrganizationModule.Organization.Update",
    OrganizationModuleOrganizationView = "OrganizationModule.Organization.View",
    PostModulePost = "PostModule.Post",
    PostModulePostCreate = "PostModule.Post.Create",
    PostModulePostDelete = "PostModule.Post.Delete",
    PostModulePostList = "PostModule.Post.List",
    PostModulePostUpdate = "PostModule.Post.Update",
    PostModulePostView = "PostModule.Post.View",
    ProjectModuleProject = "ProjectModule.Project",
    ProjectModuleProjectCreate = "ProjectModule.Project.Create",
    ProjectModuleProjectList = "ProjectModule.Project.List",
    ProjectModuleProjectUpdate = "ProjectModule.Project.Update",
    ProjectModuleProjectView = "ProjectModule.Project.View",
    ProjectModuleProjectDocument = "ProjectModule.ProjectDocument",
    ProjectModuleProjectDocumentDelete = "ProjectModule.ProjectDocument.Delete",
    ProjectModuleProjectDocumentDownload = "ProjectModule.ProjectDocument.Download",
    ProjectModuleProjectDocumentList = "ProjectModule.ProjectDocument.List",
    ProjectModuleProjectDocumentReplace = "ProjectModule.ProjectDocument.Replace",
    ProjectModuleProjectDocumentUpload = "ProjectModule.ProjectDocument.Upload",
    ProjectModuleProjectDocumentView = "ProjectModule.ProjectDocument.View",
    ProjectModuleProjectEvent = "ProjectModule.ProjectEvent",
    ProjectModuleProjectEventCreate = "ProjectModule.ProjectEvent.Create",
    ProjectModuleProjectEventView = "ProjectModule.ProjectEvent.View",
    ProjectModuleProjectTeam = "ProjectModule.ProjectTeam",
    ProjectModuleProjectTeamCreate = "ProjectModule.ProjectTeam.Create",
    ProjectModuleProjectTeamList = "ProjectModule.ProjectTeam.List",
    ProjectModuleProjectTeamView = "ProjectModule.ProjectTeam.View",
    SettingManagementEmailing = "SettingManagement.Emailing",
    UserProfileModuleUserProfile = "UserProfileModule.UserProfile",
    UserProfileModuleUserProfileCreate = "UserProfileModule.UserProfile.Create",
    UserProfileModuleUserProfileUpdate = "UserProfileModule.UserProfile.Update",
    UserProfileModuleUserProfileView = "UserProfileModule.UserProfile.View",
    WorkOrderModuleWorkOrder = "WorkOrderModule.WorkOrder",
    WorkOrderModuleWorkOrderAccept = "WorkOrderModule.WorkOrder.Accept",
    WorkOrderModuleWorkOrderCreate = "WorkOrderModule.WorkOrder.Create",
    WorkOrderModuleWorkOrderList = "WorkOrderModule.WorkOrder.List",
    WorkOrderModuleWorkOrderNote = "WorkOrderModule.WorkOrder.Note",
    WorkOrderModuleWorkOrderReject = "WorkOrderModule.WorkOrder.Reject",
    WorkOrderModuleWorkOrderView = "WorkOrderModule.WorkOrder.View",
    SubscriptionModulePackage = "SubscriptionModule.Package",
    SubscriptionModulePackageCreate = "SubscriptionModule.Package.Create",
    SubscriptionModulePackageDelete = "SubscriptionModule.Package.Delete",
    SubscriptionModulePackageList = "SubscriptionModule.Package.List",
    SubscriptionModulePackageUpdate = "SubscriptionModule.Package.Update",
    SubscriptionModulePackageView = "SubscriptionModule.Package.View",
    SubscriptionModuleSubscription = "SubscriptionModule.Subscription",
    SubscriptionModuleSubscriptionCreate = "SubscriptionModule.Subscription.Create",
    SubscriptionModuleSubscriptionDelete = "SubscriptionModuleSubscriptionDelete",
    SubscriptionModuleSubscriptionList = "SubscriptionModule.Subscription.List",
    SubscriptionModuleSubscriptionUpdate = "SubscriptionModule.Subscription.Update",
    SubscriptionModuleSubscriptionView = "SubscriptionModule.Subscription.View"
}
