import { AddressDto } from "./AddressDto";
import { ProjectFilePrivacy } from "./project-file-privacy.enum";
import { ProjectStatus } from "./project-status.enum";

export class CreateProjectDto {
  name: string;
  workType: string;
  projectType: string;
  projectManagerId: string;
  startDate: string;
  endDate: string;
  budgetedCost: number;
  size: number;
  sizeType: string;
  description: string;
  clientIds: string[];
  files: File[];
  privacy: ProjectFilePrivacy;
  address: AddressDto;
  currencyId: string;
  userProjectCode: string;
  status: ProjectStatus;
}