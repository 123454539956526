export class CreateProjectTaskModel {
    createProjectWorkOrders : CreateProjectWorkOrdersModel[] = [];
}

export class CreateProjectWorkOrdersModel{
    typeId: string;
    title : string;
    description : string;
    qty : string;
    uom : string;
    remarks : string;
}