import { Component, Injector } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConsts } from './AppConsts';
import { PermissionService } from './services/permission.service';

export abstract class AppComponentBase {

    spinnerService: NgxSpinnerService;

    permissionService: PermissionService;
    constructor(injector: Injector) {
        this.permissionService = injector.get(PermissionService);
    }


    isGranted(permissionName: string): boolean {
        return this.permissionService.isGranted(permissionName) || false;
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }
 
}
