import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  serviceEndpoint: string;
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(path, { params });
    /*.pipe(catchError(this.formatErrors));*/
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      /*`${this.serviceEndpoint}/${path}`,*/
      path,
      body
    );
    /*.pipe(catchError(this.formatErrors));*/
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      /*`${this.serviceEndpoint}/${path}`,*/
      path,
      body
    )
      .pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(
      /*`${this.serviceEndpoint}/${path}`*/
      path
    );
    /*.pipe(catchError(this.formatErrors));*/
  }

  postWithProgress(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      `${this.serviceEndpoint}/${path}`,
      body, { reportProgress: true, observe: 'events' }
    );
    /*.pipe(catchError(this.formatErrors));*/
  }

  downloadBlob(path: string): Observable<Blob> {
    return this.http.get(`${path}`, {  responseType: 'blob'});
  }
}
