import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PasswordConfirmationValidatorService {

  constructor() { }

  public validateConfirmPassword = (passwordControl: AbstractControl): ValidatorFn => {
    return (confirmationControl: AbstractControl) : { [key: string]: boolean } | null => {

      const confirmValue = confirmationControl.value;
      const passwordValue = passwordControl.value;

      if (confirmValue === '') {
          return;
      }

      if (confirmValue !== passwordValue) {
          return  { mustMatch: true }
      } 

      return null;
    };
  }

  public validateNewPassword = (oldPasswordControl: AbstractControl): ValidatorFn => {
    return (newPasswordControl: AbstractControl) : { [key: string]: boolean } | null => {

      const newPasswordValue = newPasswordControl.value;
      const oldPasswordValue = oldPasswordControl.value;

      if (newPasswordValue === '') {
          return;
      }

      if (newPasswordValue === oldPasswordValue) {
          return  { notMatch: true }
      } 

      return null;
    };
  }
}