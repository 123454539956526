import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { cloudchal } from 'src/root.module';
import { PermissionManageModel } from 'src/shared/models/permission/permission-manage-model';
import { AppConsts } from '../AppConsts';
import { Policies } from '../models/cloudchal.configuration';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  grantPermissions: Policies;
  wso2AmEndpoint: any;
  constructor(private apiService: ApiService, private router: Router) {
    this.grantPermissions = cloudchal.auth.grantedPolicies;
    this.wso2AmEndpoint = AppConsts.remoteServiceBaseUrl + "/app";
  }


  isGranted(permission: string) {
    return this.grantPermissions[permission];
  }

  checkPermissions(arrPermissions: string[]) {
    if (!arrPermissions) {
      return false;
    }

    for (const permission of arrPermissions) {
      if (this.isGranted(permission)) {
        return true;
      }
      else {
        this.router.navigate(['/app/error']);
        return of(false);
      }
    }
  }

  getPermission(userId: string) {
    return this.apiService.get(`${this.wso2AmEndpoint}/permission/permission/${userId}`);
  }

  permissionForUser(permissionManage: PermissionManageModel[]) {
    return this.apiService.post(`${this.wso2AmEndpoint}/permission/permission-for-user`, permissionManage);
  }

  grantPermissionForUser(userId: string, permissionName: string) {
    return this.apiService.post(`${this.wso2AmEndpoint}/permission/grant-permission-for-user/${userId}?permissionName=${permissionName}`);
  }

  prohibitPermissionForUser(userId: string, permissionName: string) {
    return this.apiService.post(`${this.wso2AmEndpoint}/permission/prohibit-permission-for-user/${userId}?permissionName=${permissionName}`);
  }

  getRolePermission() {
    return this.apiService.get(`${this.wso2AmEndpoint}/permission/role-permission`);
  }
}
