export class AppConsts {

    static appBaseUrl: string;
    static authServerUrl: string;
    static remoteServiceBaseUrl: string;
    static userServiceBaseUrl: string;
    static appBaseHref: string;
    static loginUrl: string;
    static domainName: string;
    static userInfo: any;
    static paymentServiceBaseUrl: string;
}