import { Injectable, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { RouterModule } from '@angular/router';
import { APP_INITIALIZER } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './environments/environment';
import { AppConsts } from './core/AppConsts';
import { PlatformLocation } from '@angular/common';
import * as _ from 'lodash';
import { CloudChalConfiguration } from './core/models/cloudchal.configuration';

@Injectable({
  providedIn: "root"
})
export class CookieStorage extends OAuthStorage {
  constructor(private cookieService: CookieService) {
    super();
  }
  getItem(key: string): string | null {
    return this.cookieService.get(key);
  }
  removeItem(key: string): void {
    if (environment.production)
      this.cookieService.delete(key, '/', "." + AppConsts.domainName, true, "Strict");
    else if (environment.staging)
      this.cookieService.delete(key, '/', "." + AppConsts.domainName, true, "Strict");
    else
      this.cookieService.delete(key, '/');
  }
  setItem(key: string, data: string): void {
    if (environment.production)
      this.cookieService.set(key, data, { domain: "." + AppConsts.domainName, secure: true, sameSite: "Strict", path: "/" });
    else if (environment.staging)
      this.cookieService.set(key, data, { domain: "." + AppConsts.domainName, secure: true, sameSite: "Strict", path: "/" });
    else
      this.cookieService.set(key, data);
  }

}

export var cloudchal: CloudChalConfiguration;

@NgModule({
  declarations: [
    RootComponent,
    LayoutComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    RootRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    RouterModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    SharedModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerFactory),
      deps: [Injector, PlatformLocation],
      multi: true
    }
  ],
  bootstrap: [RootComponent]
})
export class RootModule { }



export function getBaseHref(platformLocation: PlatformLocation): string {
  let baseUrl = platformLocation.getBaseHrefFromDOM();
  if (baseUrl) {
    return baseUrl;
  }

  return '/';
}

function getDocumentOrigin() {
  if (!document.location.origin) {
    return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
  }

  return document.location.origin;
}
export function appInitializerFactory(
  injector: Injector,
  platformLocation: PlatformLocation) {
  return () => {
    return new Promise<boolean>((resolve) => {

      let cookieStorage = injector.get(CookieStorage);
      let oAuthService = injector.get(OAuthService);
      oAuthService.setStorage(cookieStorage);

      AppConsts.appBaseHref = getBaseHref(platformLocation);
      let appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;
      let httpClient = injector.get(HttpClient);
      init(httpClient, appBaseUrl, () => {
        getAppConfiguration(httpClient, cookieStorage, () => {
          resolve(true);
        })
      });

    });
  };
}

export function init(httpClient: HttpClient, appBaseUrl: string, callback: () => void) {
  httpClient
    .get<any>(`${appBaseUrl}assets/${environment.appConfig}`, {
    })
    .subscribe((response) => {
      AppConsts.userServiceBaseUrl = response.userServiceBaseUrl;
      AppConsts.remoteServiceBaseUrl = response.remoteServiceBaseUrl;
      AppConsts.authServerUrl = response.authServerUrl;
      AppConsts.appBaseUrl = response.appBaseUrl;
      AppConsts.loginUrl = response.loginUrl;
      AppConsts.domainName = response.domainName;
      AppConsts.paymentServiceBaseUrl = response.paymentServiceBaseUrl;
      callback();
    });
}

export function getAppConfiguration(httpClient: HttpClient, cookieStorage: CookieStorage, callback: () => void): void {


  const headers = new HttpHeaders()
    .set("Authorization", "Bearer " + cookieStorage.getItem("access_token"));
  httpClient
    .get<any>(
      `${AppConsts.remoteServiceBaseUrl}/abp/application-configuration`,
      { headers: headers }
    )
    .subscribe((response) => {
      cloudchal = response;
      callback();
    });
}

export function getUserConfiguration(httpClient: HttpClient, cookieStorage: CookieStorage, callback: () => void): void {
  const headers = new HttpHeaders()
    .set("Authorization", "Bearer " + cookieStorage.getItem("access_token"));
  httpClient
    .get<any>(
      `${AppConsts.userServiceBaseUrl}/user-profile`,
      { headers: headers }
    )
    .subscribe((response) => {
      AppConsts.userInfo = response;
      callback();
    });
}

