export class CreateProjectDetailModel {
    startDate: Date;
    endDate: Date;
    size: number;
    sizeType : SizeType;
    boqFile: File[] = [];
    drawingFile: File[] = [];
    gccFile: File[] = [];
    sccFile: File[] = [];
    qualityManualFile: File[] = [];
    safetyManualFile: File[] = [];
    description: string;
    fileLinks : string;
}

export enum SizeType {
    Sqft = 1,
    Sqm = 2
}