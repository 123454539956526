import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './component/alert/alert.component';
import { MatIconModule } from '@angular/material/icon';
import { AppMaterialModule } from 'src/app/app.material.module';
import {CoreModule} from "../core/core.module";
import {NgCircleProgressModule} from "ng-circle-progress";
import { CheckboxGroupComponent } from './component/checkbox/checkbox-group.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { LinkifyPipe } from './Pipes/linkify.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProgressComponent } from './component/progress/progress.component';
import { DateAgoPipe } from './Pipes/date-ago.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    CoreModule,
    AppMaterialModule,
    NgCircleProgressModule.forRoot(),
    HighchartsChartModule,
    NgxSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    AlertComponent,
    ProgressComponent,
    AppMaterialModule,
    NgCircleProgressModule,
    HighchartsChartModule,
    LinkifyPipe,
    DateAgoPipe,
    NgxSpinnerModule
    ],
  declarations: [AlertComponent,ProgressComponent
    , LinkifyPipe,DateAgoPipe],
})
export class SharedModule { }
