export class PagedResultDto<T>{
  totalCount: number;
  items: T[] = [];
}

export class PagedResultRequestDto {
  skipCount: number;
  maxResultCount: number;
}


export  class PagedAndSortedResultRequestModel extends PagedResultRequestDto {
  sorting: string;
}
