import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { RouterModule} from '@angular/router';
import {AuthenticationService} from './services/authentication.service';
import {ApiService} from './services/api.service';
import { RegisterService } from './services/register.service';
import {ProjectService} from './services/project.service';
import { BidService } from './services/bid.service';
import { PagedListingComponentBaseComponent } from './paged-listing-component-base/paged-listing-component-base.component';
import { AlertService } from './services/alert.service';

@NgModule({
  imports: [
    CommonModule, RouterModule, HttpClientModule
  ],
  exports: [
    RouterModule, HttpClientModule
  ],
  providers: [
    AuthenticationService,
    ApiService,
    BidService,
    ProjectService,
    RegisterService,
    AlertService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class CoreModule {
}
