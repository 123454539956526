import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ProjectFilter } from '../models/project/project.filter';
import { CreateProjectDto } from '../models/project/CreateProjectDto';
import { ProjectCount, ProjectListComponentModel, ProjectListModel } from '../../shared/models/project/project-list.model';
import { AppConsts } from '../AppConsts';
import { Project } from '../../shared/models/project/project';
import { ResponseModel } from '../models/response/response.model';
import { BidderListModel } from 'src/shared/models/bidderList/bidder-list.model';
import { BidderListFilter } from 'src/shared/models/bidderList/bidder-filter';
import { PagedResultDto } from '../models/PagedResultDto';
import { SendProposalModel } from 'src/shared/models/bidderList/send-proposal.model';
import { CreateProjectDetailModel } from '../models/project/create-project-details.model';
import { CreateProjectTaskModel } from '../models/project/create-project-task.model';
import { ChangeProjectStatusModel } from '../models/project/change-project-status.model';
import { MasterDataDto } from '../models/master/MasterDataDto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(
    private apiService: ApiService
  ) {
    this.wso2AmEndpoint = AppConsts.remoteServiceBaseUrl + "/app";
  }
  wso2AmEndpoint: string;

  list(filter: ProjectFilter = new ProjectFilter()): Observable<ProjectListComponentModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/get-projects-by-status`, filter);
  }

  profileProjectChartStatus(): Observable<ProjectCount[]> {
    return this.apiService.get(`${this.wso2AmEndpoint}/project/profile-project-chart-status`);
  }
  createProject(createProject: CreateProjectDto = new CreateProjectDto()): Observable<ResponseModel> {
    const formData = new FormData();
    for (const key of Object.keys(createProject)) {
      if(createProject[key]===null){
        continue;
      }
      if (typeof (createProject[key]) === 'object') {
        if (key == "address") {
          formData.append("address.addressLine1", createProject.address.addressLine1);
          formData.append("address.addressLine2", createProject.address.addressLine2);
          formData.append("address.countryId", createProject.address.countryId);
          formData.append("address.stateId", createProject.address.stateId);
          formData.append("address.cityId", createProject.address.cityId);
          formData.append("address.pinCode", createProject.address.pinCode);
        }
        else {
          for (const i of createProject[key]) {
            formData.append(key, i);
          }
        }
      }
      else {
        formData.append(key, createProject[key]);
      }
    }
    return this.apiService.post(`${this.wso2AmEndpoint}/project/project`, formData);
  }
  createProjectDetail(createProjectDetail: CreateProjectDetailModel = new CreateProjectDetailModel(), id: string): Observable<any> {
    const formData = new FormData();
    for (const key of Object.keys(createProjectDetail)) {
      if (typeof (createProjectDetail[key]) === 'object') {
        for (const i of createProjectDetail[key]) {
          formData.append(key, i);
        }
      }
      else {
        formData.append(key, createProjectDetail[key]);
      }
    }
    return this.apiService.post(`${this.wso2AmEndpoint}/project/${id}/project-detail`, formData);
  }
  createProjectTask(createProjectTask: CreateProjectTaskModel = new CreateProjectTaskModel(), id: string): Observable<any> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/${id}/project-task`, createProjectTask);
  }

  editProject(projectId: string,createProject: CreateProjectDto = new CreateProjectDto()): Observable<any> {
    const formData = new FormData();
    for (const key of Object.keys(createProject)) {
      if(createProject[key]===null){
        continue;
      }
      if (typeof (createProject[key]) === 'object') {
        if (key == "address") {
          formData.append("address.addressLine1", createProject.address.addressLine1);
          formData.append("address.addressLine2", createProject.address.addressLine2);
          formData.append("address.countryId", createProject.address.countryId);
          formData.append("address.stateId", createProject.address.stateId);
          formData.append("address.cityId", createProject.address.cityId);
          formData.append("address.pinCode", createProject.address.pinCode);
        }
        else {
          for (const i of createProject[key]) {
            formData.append(key, i);
          }
        }
      }
      else {
        formData.append(key, createProject[key]);
      }
    }
    return this.apiService.post(`${this.wso2AmEndpoint}/project/${projectId}/update`, formData);
    }
  
  get(id: string): Observable<Project> {
    return this.apiService.get(`${this.wso2AmEndpoint}/project?projectId=${id}`);
  }

  activeList(filter: ProjectFilter = new ProjectFilter()): Observable<ProjectListComponentModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/get-projects-by-status`, filter);
  }

  closedList(filter: ProjectFilter = new ProjectFilter()): Observable<ProjectListComponentModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/get-projects-by-closed-status`, filter);
  }

  bidderProjectList(filter: ProjectFilter = new ProjectFilter()): Observable<ProjectListComponentModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/get-projects-by-bidder`, filter);
  }

  bidderClosedProjectList(filter: ProjectFilter = new ProjectFilter()): Observable<ProjectListComponentModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/get-closed-projects-by-bidder`, filter);
  }

  floatTender(projectId: string): Observable<ResponseModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/project/${projectId}/float-tender-project`);
  }

  bidderList(filter: BidderListFilter, projectId: string): Observable<PagedResultDto<BidderListModel>> {
    return this.apiService.post(`${this.wso2AmEndpoint}/${projectId}/bidders/bidders`, filter);
  }

  sendProposal(sendProposal: SendProposalModel, projectId: string): Observable<any> {
    return this.apiService.post(`${this.wso2AmEndpoint}/${projectId}/bidders/send-proposal`, sendProposal);
  }

  acceptOrReject(projectId: string, isAccept: boolean): Observable<ResponseModel> {
    return this.apiService.post(`${this.wso2AmEndpoint}/${projectId}/inviteResponse?isAccept=${isAccept}`)
  }

  changeProjectStatus(changeProjectStatusModel:ChangeProjectStatusModel): Observable<ResponseModel>{
    return this.apiService.post(`${this.wso2AmEndpoint}/project/change-project-status`,changeProjectStatusModel)
  }
  
  getProjectListData(): Observable<MasterDataDto[]>{
    return this.apiService.get(`${this.wso2AmEndpoint}/project/project-list`)
  }

  getJsonData(name: string) {
    return this.apiService.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'+name+'.json?access_token='+environment.mapbox.accessToken);
  }
}
